import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainContainer from './components/layout/MainContainer';
import { routes, authRoutes } from './utils/constants/route';
import { NavbarProvider } from 'context/NavbarContext';
import { VersionHistoryProvider } from 'context/VersionHistoryContext';
import { AuthProvider } from 'context/AuthContext';
import { LoadingProvider } from 'context/LoadingContext';
import { SnackbarProvider } from 'context/SnackbarContext';
import { UNDER_MAINTENANCE } from 'utils/constants';
import UnderMaintenance from 'components/under-maintenance/UnderMaintenance';

let router = createBrowserRouter([...routes, ...authRoutes]);

function App() {
  return (
    <SnackbarProvider>
      <LoadingProvider>
        <AuthProvider>
          <NavbarProvider>
            <VersionHistoryProvider>
              {UNDER_MAINTENANCE ? (
                <UnderMaintenance />
              ) : (
                <MainContainer>
                  <RouterProvider
                    router={router}
                    fallbackElement={<p>Loading...</p>}
                  />
                </MainContainer>
              )}
            </VersionHistoryProvider>
          </NavbarProvider>
        </AuthProvider>
      </LoadingProvider>
    </SnackbarProvider>
  );
}

export default App;
