import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import RegisterIllustration from 'assets/illustrations/register-illustration.png';
import TabHeader from 'components/tab/TabHeader';
import AppCard from 'components/card/base/AppCard';
import TextInput from 'components/input/TextInput';
import TextArea from 'components/input/TextArea';
import Button from 'components/button/Button';
import useFetch from 'utils/hooks/useFetch';
import { REGISTER } from 'utils/constants/api';
import Cookies from 'js-cookie';

function Register() {
  const { isSubmitting: submittingRegister, fetch: register } = useFetch(REGISTER);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      job: '',
      company: '',
      purpose: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name must be filled'),
      email: Yup.string().email('Invalid email address').required('Email must be filled'),
      password: Yup.string().required('Password must be filled'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password must be filled'),
      job: Yup.string().required('Job must be filled'),
      company: Yup.string().required('Company must be filled'),
      purpose: Yup.string().required('Purpose must be filled'),
    }),
    onSubmit: async (values) => {
      const res = await register({
        data: {
          name: values?.name,
          email: values?.email,
          job_title: values?.job,
          company_nm: values?.company,
          purpose: values?.purpose,
          password: values?.password,
        },
      });
      if (res.access_token) {
        Cookies.set('ut', res.access_token);
        window.location.href = '/';
      }
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <div className="bg-app-black-primary flex-1 flex place-content-center">
      <div className="app-main-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-8">
        <div className="flex justify-center items-start col-span-1 lg:col-span-3">
          <img
            className="object-contain"
            src={RegisterIllustration}
            alt="register-illustration"
          />
        </div>
        <AppCard className="col-span-1 lg:col-span-2 space-y-8">
          <TabHeader
            title="Sign Up"
            description="Be one of the first to explore the future of textile innovation with our Generative AI platform. Join the beta to access exclusive features and experience cutting-edge creativity powered by AI."
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextInput
              id="name"
              name="name"
              type="text"
              label="Name"
              placeholder="Write Your Name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.name}
              error={formik.errors.name}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="email"
              name="email"
              type="email"
              label="E-mail"
              placeholder="Write Your E-mail"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.email}
              error={formik.errors.email}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="password"
              name="password"
              className="md:col-span-2"
              type="password"
              label="Password"
              placeholder="Write Your password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.password}
              error={formik.errors.password}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="confirmPassword"
              name="confirmPassword"
              className="md:col-span-2"
              type="password"
              label="Confirm Password"
              placeholder="Write Your password"
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.confirmPassword}
              error={formik.errors.confirmPassword}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="job"
              name="job"
              type="text"
              label="Job"
              placeholder="Write Your Job"
              value={formik.values.job}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.job}
              error={formik.errors.job}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="company"
              name="company"
              type="text"
              label="Company"
              placeholder="Write Your Company"
              value={formik.values.company}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.company}
              error={formik.errors.company}
              onKeyDown={handleKeyDown}
            />
            <TextArea
              id="purpose"
              name="purpose"
              title="Purpose of Using"
              className="md:col-span-2"
              placeholder="Write Your Purpose"
              value={formik.values.purpose}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.purpose}
              error={formik.errors.purpose}
              onSend={handleKeyDown}
            />
          </div>
          <div className="flex flex-col space-y-3 items-center">
            <Button
              isSubmitting={submittingRegister}
              onClick={formik.handleSubmit}
              className="w-full"
              text="Sign Up"
            />
            <div className="h-8">
              <p className="text-sm">
                Have an account?{' '}
                <a
                  href="/login"
                  className="text-app-icon-info underline font-semibold
"
                >
                  Sign In to Account
                </a>
              </p>
            </div>
          </div>
        </AppCard>
      </div>
    </div>
  );
}

export default Register;
