export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const NODE_ENV_DEVELOPMENT = 'development';
export const NODE_ENV_PRODUCTION = 'production';
export const IS_DEV_ENV = APP_ENVIRONMENT === NODE_ENV_DEVELOPMENT;
export const UNDER_MAINTENANCE = process.env.REACT_APP_MAINTENANCE === 'true';
export const IS_MOBILE_SCREEN = window.innerWidth < 640;

export const NUM_OF_OUTPUTS = [3, 6, 9];

export const TABS = [
  { id: 1, name: 'Step 1. Initial Input' },
  { id: 2, name: 'Step 2. Customization Process' },
  { id: 3, name: 'Step 3. Texture Maps' },
];

export const STEP1_TABS = [
  { id: 1, name: 'Text' },
  { id: 2, name: 'Image' },
];

export const FABRIC_TYPES = [
  { label: 'Woven', value: 'woven' },
  { label: 'Knit', value: 'knit' },
];

export const FABRIC_COLORS = [
  { label: 'Green', value: '#50C878' },
  { label: 'Red', value: '#C8102E' },
  { label: 'Blue', value: '#87CEEB' },
  { label: 'Gray', value: '#36454F' },
  { label: 'Yellow', value: '#FFDA03' },
  { label: 'Purple', value: '#6A0D91' },
  { label: 'White', value: '#FFFDD0' },
  { label: 'Orange', value: '#FF7F50' },
  { label: 'Indigo', value: '#6A5ACD' },
  { label: 'Teal', value: '#008080' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Brown', value: '#3D0A02' },
  { label: 'Olive', value: '#6B8E23' },
  { label: 'Crimson', value: '#DC143C' },
  { label: 'Lavender', value: '#E6E6FA' },
  { label: 'Turquoise', value: '#40E0D0' },
  { label: 'Mauve', value: '#E0B0FF' },
  { label: 'Rust', value: '#B7410E' },
  { label: 'Mint', value: '#98FF98' },
  { label: 'Navy', value: '#001F3F' },
];
export const FABRIC_PATTERNS = [
  { label: 'Solid', value: 'solid' },
  { label: 'Stripes', value: 'stripes' },
  { label: 'Polka Dots', value: 'polka-dots' },
  { label: 'Plaid', value: 'plaid' },
  { label: 'Checks', value: 'checks' },
  { label: 'Houndstooth', value: 'houndstooth' },
  { label: 'Herringbone', value: 'herringbone' },
  { label: 'Floral', value: 'floral' },
  { label: 'Paisley', value: 'paisley' },
  { label: 'Geometric', value: 'geometric' },
  { label: 'Abstract', value: 'abstract' },
  { label: 'Tie-Dye', value: 'tie-dye' },
  { label: 'Chevron', value: 'chevron' },
  { label: 'Damask', value: 'damask' },
  { label: 'Brocade', value: 'brocade' },
  { label: 'Quilted', value: 'quilted' },
  { label: 'Batiks', value: 'batiks' },
  { label: 'Corduroy', value: 'corduroy' },
];
