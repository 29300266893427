import EyeIcon from 'assets/icons/EyeIcon';
import React, { useState } from 'react';

function TextInput({
  id,
  name,
  className,
  placeholder = '',
  onChange,
  onBlur,
  value = '',
  type,
  required,
  disabled,
  readOnly,
  autoFocus,
  label,
  touched,
  error,
  autoComplete = 'off',
  onKeyDown,
}) {
  const [_type, setType] = useState(type);
  const changeVisibility = () => {
    setType(_type === 'password' ? 'text' : 'password');
  };
  return (
    <div className={`flex flex-col gap-1 sm:gap-2 ${className}`}>
      <label
        className="text-sm font-bold"
        htmlFor={id}
      >
        {label}
      </label>
      <div>
        <div className="w-full relative flex flex-col justify-center">
          <input
            id={id}
            name={name}
            className={`w-full text-xs sm:text-sm p-3 border ${
              touched && error ? 'border-app-red' : 'border-app-light-grey'
            }  rounded-md bg-transparent`}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            type={_type}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
          />
          {type === 'password' && (
            <button
              onClick={changeVisibility}
              className="absolute right-0 mr-3"
            >
              <EyeIcon
                className={`${
                  _type === 'text' ? 'h-6 w-6 fill-app-brand' : 'h-6 w-6 fill-app-icon-primary'
                }`}
              />
            </button>
          )}
        </div>
        {touched && error ? (
          <div className="text-xs text-app-red-secondary mt-1">{error}</div>
        ) : null}
      </div>
    </div>
  );
}

export default TextInput;
