import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';

import AppDropdown from 'components/dropdown/base/AppDropdown';
import MenuNav from 'components/menu/MenuNav';

import FrontierLogo from 'assets/icons/FrontierLogo';
import CoinsIcon from 'assets/icons/CoinsIcon';
import HistoryIcon from 'assets/icons/HistoryIcon';
import AvatarIcon from 'assets/icons/AvatarIcon';
import ExitIcon from 'assets/icons/ExitIcon';

import { useNavbarState } from 'context/NavbarContext';
import { useVersionHistoryState } from 'context/VersionHistoryContext';
import { useAuthState } from 'context/AuthContext';

import usePathname from 'utils/hooks/usePathname';
import useFetch from 'utils/hooks/useFetch';
import { LOGOUT } from 'utils/constants/api';
import { authRoutes } from 'utils/constants/route';

import CloseIcon from 'assets/icons/CloseIcon';
import SidebarIcon from 'assets/icons/SidebarIcon';
import { getCookie } from 'utils/helpers';
import Tooltip from 'components/tooltip/Tooltip';

function Navbar() {
  const navbarRef = useRef(null);

  const { authState } = useAuthState();
  const { navbarState, setNavbarState } = useNavbarState();
  const { versionHistoryState, setVersionHistoryState } = useVersionHistoryState();

  const currentPathname = usePathname();
  const { fetch: logout } = useFetch(LOGOUT);
  const urlParams = new URLSearchParams(window.location.search);

  const fabricCd = urlParams.get('f_cd');
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const isAuthPage = authRoutes.some((route) => window.location.pathname === route.path);

  const toggleMenu = () => {
    setNavbarState((prev) => ({ ...prev, menu: { ...prev.menu, isOpen: !prev?.menu?.isOpen } }));
    if (!navbarState?.menu?.isOpen) {
      setVersionHistoryState((prev) => ({ ...prev, isOpen: false }));
    }
  };

  const openVersionHistory = () => {
    setVersionHistoryState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
    if (!versionHistoryState?.isOpen) {
      setNavbarState((prev) => ({ ...prev, menu: { ...prev.menu, isOpen: false } }));
    }
  };

  const handleLogout = async () => {
    const refreshToken = getCookie('rt');

    await logout({
      query: {
        refresh_token: refreshToken && refreshToken !== 'undefined' ? refreshToken : '',
      },
    });
    Cookies.remove('ut');
    Cookies.remove('rt');
    window.location.href = '/login';
    window.location.reload();
  };

  useEffect(() => {
    setNavbarState((prev) => ({ ...prev, size: navbarRef.current.clientHeight }));
  }, [setNavbarState]);

  return (
    <div
      ref={navbarRef}
      className={`sticky top-0 bg-app-black-primary shadow-sm transition-all z-30`}
    >
      <div
        className={`app-nav-container flex ${
          isAuthPage ? 'justify-center' : 'justify-between'
        } items-center gap-6 md:text-xs lg:text-base`}
      >
        {isAuthPage ? (
          <FrontierLogo />
        ) : (
          <a
            href="/"
            className="font-bold text-base sm:text-lg"
          >
            <FrontierLogo />
          </a>
        )}

        {!isAuthPage && (
          <div className="flex space-x-2 sm:space-x-4">
            <Tooltip
              className="inline-block sm:hidden"
              trigger="click"
              position="bottom"
              text={
                <div className="flex gap-2 px-2 py-1 sm:py-2 items-center bg-app-black-secondary rounded text-xs sm:text-sm">
                  <CoinsIcon />
                  <p>{authState?.credit || 0} Coins</p>
                </div>
              }
            >
              <div className="flex gap-2 px-2 py-1 sm:py-2 items-center bg-app-black-secondary rounded text-xs sm:text-sm cursor-pointer">
                <CoinsIcon />
                <p className="truncate max-w-12 sm:max-w-none">{authState?.credit || 0} Coins</p>
              </div>
            </Tooltip>
            <div className="hidden sm:flex gap-2 px-2 py-1 sm:py-2 items-center bg-app-black-secondary rounded text-xs sm:text-sm">
              <CoinsIcon />
              <p className="truncate max-w-12 sm:max-w-none">{authState?.credit || 0} Coins</p>
            </div>
            <div className="hidden sm:block border-r border-white" />

            {currentPathname === '/generator' && fabricCd ? (
              <button
                className="flex gap-2 items-center font-semibold"
                onClick={openVersionHistory}
              >
                <HistoryIcon
                  className={`${
                    versionHistoryState?.isOpen
                      ? 'h-4 sm:h-5 w-4 sm:w-5 fill-app-icon-info -rotate-45'
                      : 'h-4 sm:h-5 w-4 sm:w-5 fill-app-white-primary'
                  } transition-all`}
                />
                <p
                  className={`hidden sm:flex text-base ${
                    versionHistoryState?.isOpen ? ' text-app-icon-info' : 'text-app-white-primary'
                  }`}
                >
                  History
                </p>
              </button>
            ) : (
              <AppDropdown
                className="hidden sm:inline-block"
                buttonComponent={
                  <button
                    className="flex gap-2 items-center text-sm"
                    onClick={() => setOpenDropdown(!isOpenDropdown)}
                  >
                    <div className="bg-[#084535] rounded-full w-8 h-8 flex justify-center items-center">
                      <AvatarIcon className="fill-[#75b7a3] w-4 h-4" />
                    </div>
                    <p>{authState?.user?.name}</p>
                  </button>
                }
                isOpen={isOpenDropdown}
                setOpen={setOpenDropdown}
              >
                <div className="bg-app-black-secondary px-4 py-3 text-sm min-w-28 space-y-4">
                  <button
                    onClick={() => (window.location.href = '/profile')}
                    className="flex items-center space-x-2"
                  >
                    <div className="h-6 w-6 flex items-center">
                      <AvatarIcon className="w-5 h-5 fill-app-icon-primary" />
                    </div>
                    <p>Profile</p>
                  </button>
                  <button
                    onClick={handleLogout}
                    className="flex items-center space-x-2"
                  >
                    <div className="h-6 w-6 flex items-center">
                      <ExitIcon className="w-6 h-6 fill-app-icon-primary" />
                    </div>
                    <p>Logout</p>
                  </button>
                </div>
              </AppDropdown>
            )}
            <button
              className="inline-block sm:hidden"
              onClick={toggleMenu}
            >
              {navbarState?.menu?.isOpen ? (
                <CloseIcon className="fill-app-white-primary h-6 w-6 " />
              ) : (
                <SidebarIcon className="fill-app-white-primary h-6 w-6 " />
              )}
            </button>
          </div>
        )}

        <MenuNav
          isOpen={navbarState?.menu?.isOpen}
          authState={authState}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
}

export default Navbar;
