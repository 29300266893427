import CloseIcon from 'assets/icons/CloseIcon';
import React, { useState, useEffect } from 'react';

const Snackbar = ({ message, type = 'info', isVisible, onClose }) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  const typeStyles = {
    success: 'bg-green-600 text-app-white-primary',
    error: 'bg-red-600 text-app-white-primary',
    info: 'bg-blue-600 text-app-white-primary',
    warning: 'bg-yellow-600 text-app-black-primary',
  };

  const iconStyles = {
    success: 'fill-app-white-primary',
    error: 'fill-app-white-primary',
    info: 'fill-app-white-primary',
    warning: 'fill-app-black-primary',
  };

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <div
      className={`fixed z-50 top-3 sm:top-5 right-3 sm:right-5 transition-all duration-300 ${
        visible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-96'
      } ${
        typeStyles[type]
      } py-3 px-4 rounded shadow-lg flex gap-4 text-xs sm:text-sm w-60 sm:w-80 justify-between items-start`}
    >
      <span>{message}</span>
      <button onClick={handleClose}>
        <CloseIcon className={`w-5 sm:w-6 h-5 sm:h-6 ${iconStyles[type]}`} />
      </button>
    </div>
  );
};

export default Snackbar;
