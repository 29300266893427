import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';

import TabHeader from 'components/tab/TabHeader';
import TextInput from 'components/input/TextInput';
import Button from 'components/button/Button';

import { CHANGE_PASSWORD, LOGOUT } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import AppModal from './base/AppModal';
import { useSnackbar } from 'context/SnackbarContext';
import { getCookie } from 'utils/helpers';

function ChangePasswordModal({ onClose }) {
  const { showSnackbar } = useSnackbar();
  const [isSuccess, setSuccess] = useState(false);
  const { fetch: changePassword } = useFetch(CHANGE_PASSWORD);
  const { fetch: logout } = useFetch(LOGOUT);
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old Password must be filled'),
      password: Yup.string().required('Password must be filled'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password must be filled'),
    }),
    onSubmit: async () => {
      const res = await changePassword({
        data: {
          old_password: formik.values.oldPassword,
          password: formik.values.password,
        },
      });

      if (res.error) {
        showSnackbar({
          message: res?.error?.response?.data?.data || 'Something went wrong',
          type: 'error',
        });
      } else {
        setSuccess(true);
        Cookies.remove('ut');
        Cookies.remove('rt');
      }
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const handleLogout = async () => {
    const refreshToken = getCookie('rt');

    await logout({
      query: {
        refresh_token: refreshToken && refreshToken !== 'undefined' ? refreshToken : '',
      },
    });
    Cookies.remove('ut');
    Cookies.remove('rt');
    window.location.href = '/login';
    window.location.reload();
  };

  return (
    <AppModal>
      {isSuccess ? (
        <div className="flex flex-col items-center text-center">
          <p className="font-bold text-xl mb-2">Successfully Changed the Password</p>
          <p className="mb-6">
            You have successfully changed your password, you must sign in again with your new
            password.
          </p>
          <Button
            className="w-full max-w-48"
            onClick={handleLogout}
            text="Re-login"
            isSubmitting={false}
          />
        </div>
      ) : (
        <div className="space-y-8">
          <TabHeader title="Change Password" />
          <div className="space-y-4">
            <TextInput
              id="oldPassword"
              name="oldPassword"
              className="sm:col-span-2"
              type="password"
              label="Old Password"
              placeholder="Write Your password"
              value={formik.values.oldPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.oldPassword}
              error={formik.errors.oldPassword}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="password"
              name="password"
              className="sm:col-span-2"
              type="password"
              label="Password"
              placeholder="Write Your password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.password}
              error={formik.errors.password}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="confirmPassword"
              name="confirmPassword"
              className="sm:col-span-2"
              type="password"
              label="Confirm Password"
              placeholder="Write Your password"
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.confirmPassword}
              error={formik.errors.confirmPassword}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex gap-2 items-center">
            <Button
              className="flex-1 min-w-0"
              onClick={onClose}
              text="Cancel"
              type="secondary"
              isSubmitting={false}
            />
            <Button
              className="flex-1 min-w-0"
              onClick={formik.handleSubmit}
              text="Save"
              isSubmitting={false}
            />
          </div>
        </div>
      )}
    </AppModal>
  );
}

export default ChangePasswordModal;
