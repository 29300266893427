import { useState } from 'react';

const Tooltip = ({
  className = '',
  text,
  children,
  trigger = 'hover',
  type = 'neutral',
  position = 'top',
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    if (trigger === 'hover' || trigger === 'both') {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (trigger === 'hover' || trigger === 'both') {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    if (trigger === 'click' || trigger === 'both') {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }
  };

  return (
    <div
      className={`inline-block relative ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span className="hover:opacity-80 transition-all">{children}</span>
      <div
        className={`absolute z-10 left-1/2 w-max max-w-60 ${
          position === 'top' ? '-top-6 sm:-top-8' : 'top-10 sm:top-12'
        } -translate-x-1/2 transform ${
          isVisible ? 'opacity-100 mb-2 h-auto px-2 py-1' : 'opacity-0 mb-0 h-0 px-0 py-0'
        }  transition-all rounded ${
          type === 'success' ? 'bg-app-brand' : 'bg-app-black-secondary'
        } text-white text-[10px] sm:text-xs`}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
