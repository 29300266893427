import AvatarIcon from 'assets/icons/AvatarIcon';
import ExitIcon from 'assets/icons/ExitIcon';
import React from 'react';

function MenuNav({ isOpen, authState, handleLogout }) {
  return (
    <div
      className={`absolute top-0 mt-[60px] sm:hidden right-0 left-0 bg-app-black-primary shadow-md overflow-hidden transition-all duration-200 ${
        isOpen ? 'max-h-[400px] opacity-100' : 'max-h-0 opacity-0'
      }`}
    >
      <div className="flex flex-col px-4 py-2 space-y-2">
        <div className="flex gap-2 items-center text-sm">
          <div className="bg-[#084535] rounded-full w-8 h-8 flex justify-center items-center">
            <AvatarIcon className="fill-[#75b7a3] w-4 h-4" />
          </div>
          <p>{authState?.user?.name}</p>
        </div>
        <div className="block border-b border-white" />
        <div className="sm:bg-app-black-secondary text-xs sm:text-sm sm:min-w-28 font-semibold space-y-2">
          <button
            onClick={() => (window.location.href = '/profile')}
            className="flex items-center space-x-2"
          >
            <div className="w-8 h-8 flex items-center">
              <AvatarIcon className="w-5 h-5 fill-app-icon-primary" />
            </div>
            <p>Profile</p>
          </button>

          <button
            onClick={handleLogout}
            className="flex items-center space-x-2"
          >
            <div className="w-8 h-8 flex items-center">
              <ExitIcon />
            </div>
            <p>Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MenuNav;
