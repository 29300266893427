import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { BASE_URL } from 'utils/constants/api';

export const setCookie = (name, value, onSuccess = () => {}) => {
  if (value && value !== undefined) {
    const decodedToken = jwtDecode(value);
    const expirationInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationInSeconds * 1000);
    Cookies.set(name, value, { expires: expirationDate });
    onSuccess();
  }
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const decodeToken = (token) => {
  if (token && token !== 'undefined') {
    return jwtDecode(token);
  } else {
    return null;
  }
};

export async function urlToFile(
  url,
  filename,
  mimeType = 'image/jpeg',
  contentType = 'image/jpeg'
) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();

    const metadata = { type: mimeType };

    return new File([blob], filename, metadata);
  } catch (error) {
    console.error('Error fetching the image:', error);
    throw error;
  }
}

export const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

export const parseUrl = (url) => {
  const base = BASE_URL || window.location.origin;

  if (url) {
    return new URL(url, base)?.href;
  } else {
    return base;
  }
};
export const refetchImage = async (paths, setLoading) => {
  const id = setInterval(async () => {
    try {
      const promises = paths?.map((path) => fetch(parseUrl(path)));
      const res = await Promise.all(promises);
      const contentType = res?.map((r) => r?.headers?.get('Content-Type'));

      if (contentType?.every((type) => type?.startsWith('image')) && res?.every((r) => r?.ok)) {
        setLoading(false);
        clearInterval(id);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  }, 2500);
};

export const fetchImage = async (paths, delay = 0, setLoading) => {
  if (paths && delay) {
    setTimeout(async () => {
      try {
        const promises = paths?.map((path) => fetch(parseUrl(path)));
        const res = await Promise.all(promises);
        const contentType = res?.map((r) => r?.headers?.get('Content-Type'));

        if (contentType?.every((type) => type?.startsWith('image')) && res?.every((r) => r?.ok)) {
          setLoading(false);
        } else {
          refetchImage(paths, setLoading);
        }
      } catch (error) {
        refetchImage(paths, setLoading);
      }
    }, delay * 1000);
  } else {
    setLoading(false);
  }
};
