import CloseIcon from 'assets/icons/CloseIcon';
import SearchIcon from 'assets/icons/SearchIcon';

function Search({ value, onChange, onClear }) {
  return (
    <div className="relative border border-[#555656] flex items-center gap-2 w-full sm:w-80 py-2 px-2 sm:px-3 rounded">
      <SearchIcon className="w-5 sm:w-6 h-5 sm:h-6 fill-app-icon-primary" />
      <input
        className="text-sm sm:text-base bg-transparent outline-none flex-1"
        placeholder="Search"
        value={value}
        onChange={onChange}
      />
      {value && (
        <button onClick={onClear}>
          <CloseIcon className="fill-app-white-primary h-6 w-6" />
        </button>
      )}
    </div>
  );
}

export default Search;
