import { createContext, useContext, useState } from 'react';

export const SnackbarContext = createContext();
export const SnackbarUpdateContext = createContext();

export const useSnackbar = () => {
  const snackbar = useContext(SnackbarContext);
  const setSnackbar = useContext(SnackbarUpdateContext);

  const showSnackbar = ({ message, type }) => {
    setSnackbar({ isVisible: true, message, type });
  };
  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, isVisible: false });
  };

  return {
    snackbar,
    showSnackbar,
    closeSnackbar,
  };
};

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    isVisible: false,
    message: '',
    type: 'info',
  });

  return (
    <SnackbarContext.Provider value={snackbar}>
      <SnackbarUpdateContext.Provider value={setSnackbar}>
        {children}
      </SnackbarUpdateContext.Provider>
    </SnackbarContext.Provider>
  );
};
