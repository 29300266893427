import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_URL } from 'utils/constants/api';

const baseURL = API_BASE_URL;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get('ut') || '';
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // Modify the response data here

    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (originalConfig.url !== '/login' && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const res = await axios.get(`${API_BASE_URL}/auth/refresh`, {
            params: {
              refresh_token: Cookies.get('rt'),
            },
          });

          const accessToken = res.data.access_token;
          const refreshToken = res.data.refresh_token;

          Cookies.set('ut', accessToken);
          Cookies.set('rt', refreshToken);

          return api(originalConfig);
        } catch (_error) {
          Cookies.remove('ut');
          window.location.href = '/login';

          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
