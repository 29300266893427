import React from 'react';
import FrontierLogo from 'assets/icons/FrontierLogo';

const UnderMaintenance = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-app-black-secondary">
      <div className="flex flex-col items-center justify-center gap-4 text-center px-6 py-8 bg-app-black-primary shadow-lg rounded-lg max-w-md">
        <FrontierLogo />
        <h1 className="text-4xl font-bold text-app-white-primary">We'll be right back!</h1>
        <p className="text-app-white-primary/80 text-lg">
          Our site is currently undergoing maintenance to improve your experience. Thank you for
          your patience!
        </p>
      </div>
    </div>
  );
};

export default UnderMaintenance;
