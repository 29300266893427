import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AvatarIcon from 'assets/icons/AvatarIcon';
import Button from 'components/button/Button';
import AppCard from 'components/card/base/AppCard';
import TextInput from 'components/input/TextInput';
import ChangePasswordModal from 'components/modal/ChangePasswordModal';
import useFetch from 'utils/hooks/useFetch';
import { GET_PROFILE, UPDATE_PROFILE } from 'utils/constants/api';
import { useSnackbar } from 'context/SnackbarContext';

function Profile() {
  const { data: profile, loading: loadingProfile, fetch: getProfile } = useFetch(GET_PROFILE);
  const { fetch: updateProfile } = useFetch(UPDATE_PROFILE);
  const { showSnackbar } = useSnackbar();
  const [changePasswordModal, setChangePasswordModal] = useState({ isOpen: false });

  const formik = useFormik({
    initialValues: {
      name: profile?.data?.name,
      job: profile?.data?.job_title,
      company: profile?.data?.company_nm,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Name must be filled'),
      job: Yup.string().required('Job must be filled'),
      company: Yup.string().required('Company must be filled'),
    }),
    onSubmit: async (values) => {
      const res = await updateProfile({
        data: {
          name: values.name,
          job_title: values.job,
          company_nm: values.company,
        },
      });

      if (res.error) {
        showSnackbar({
          message: 'Something went wrong',
          type: 'success',
        });
      } else {
        showSnackbar({
          message: 'Profile updated successfully',
          type: 'success',
        });
        getProfile();
      }
    },
  });

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  if (loadingProfile) return null;

  return (
    <div className="app-main-container max-w-3xl">
      <AppCard className="space-y-6">
        <div className="flex flex-wrap items-center gap-4">
          <div className="bg-[#084535] rounded-full w-16 sm:w-20 h-16 sm:h-20 flex justify-center items-center">
            <AvatarIcon className="fill-[#75b7a3] w-8 sm:w-12 h-8 sm:h-12" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h3 className="text-sm font-bold">Email address</h3>
            <p className="text-xs sm:text-sm mt-2">{profile?.data?.email}</p>
          </div>
          <TextInput
            id="name"
            name="name"
            type="text"
            label="Name"
            placeholder="Write Your Name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched.name}
            error={formik.errors.name}
          />
          <TextInput
            id="job"
            name="job"
            type="text"
            label="Job"
            placeholder="Write Your Job"
            value={formik.values.job}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched.job}
            error={formik.errors.job}
          />
          <TextInput
            id="company"
            name="company"
            type="text"
            label="Company"
            placeholder="Write Your Company"
            value={formik.values.company}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched.company}
            error={formik.errors.company}
          />
        </div>
        <div className="grid md:grid-cols-2 order-first">
          <div />
          <div className="flex flex-col sm:flex-row justify-end gap-4">
            <Button
              className="flex-1"
              text="Change Password"
              type="secondary"
              onClick={() => setChangePasswordModal((prev) => ({ ...prev, isOpen: true }))}
            />
            <Button
              disabled={!formik.dirty}
              className="flex-1"
              text="Save"
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </AppCard>
      {changePasswordModal.isOpen && (
        <ChangePasswordModal
          onClose={() => setChangePasswordModal((prev) => ({ ...prev, isOpen: false }))}
        />
      )}
    </div>
  );
}

export default Profile;
