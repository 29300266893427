import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Button from 'components/button/Button';
import AppCard from 'components/card/base/AppCard';
import ResultCard from 'components/card/ResultCard';
import AppImage from 'components/image/base/AppImage';
import SectionLabel from 'components/input/SectionLabel';
import Select from 'components/input/Select';
import TextArea from 'components/input/TextArea';
import ImageModal from 'components/modal/ImageModal';
import TabHeader from 'components/tab/TabHeader';
import { useSnackbar } from 'context/SnackbarContext';
import { STEP1_TABS } from 'utils/constants';
import { parseUrl } from 'utils/helpers';

function Tab1({
  message,
  images,
  isSubmitting,
  fabrics,
  selectedImage,
  onChangeMessage,
  setImages,
  onSubmitText,
  onSubmitImages,
  setSelectedImage,
  endOfPageRef,
  handleChangeTab,
  activeStep1Tab,
  setActiveStep1Tab,
  fabricTypes,
  fabricType,
  setFabricType,
}) {
  const inputFileRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const [imageModal, setImageModal] = useState({
    index: 0,
    isOpen: false,
    text: '',
  });

  const openModal = (index, text) => {
    setImageModal({
      index,
      isOpen: true,
      text,
    });
  };

  const closeModal = () => {
    setImageModal({
      isOpen: false,
      text: '',
    });
  };

  const handleFileUpload = (e) => {
    Array.from(e.target.files).forEach((file, i) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target.result;

        img.onload = () => {
          if (img.width >= 256 && img.height >= 256) {
            setImages([
              {
                file,
                preview: URL.createObjectURL(file),
                thumbnail: URL.createObjectURL(file),
              },
            ]);
          } else {
            showSnackbar({
              message: 'Image must be at least 256x256 pixels',
              type: 'error',
            });
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    Array.from(acceptedFiles).forEach((file, i) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target.result;

        img.onload = () => {
          if (img.width >= 256 && img.height >= 256) {
            setImages([
              {
                file,
                preview: URL.createObjectURL(file),
                thumbnail: URL.createObjectURL(file),
              },
            ]);
          } else {
            showSnackbar({
              message: 'Image must be at least 256x256 pixels',
              type: 'error',
            });
          }
        };
      };

      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    multiple: false,
    onDrop,
  });

  const onSubmit = () => {
    if (activeStep1Tab?.id === 1) {
      onSubmitText();
    }
    if (activeStep1Tab?.id === 2) {
      onSubmitImages();
    }
  };

  const disableSend =
    (activeStep1Tab?.id === 1 ? !message && !fabricType : !images.length) || isSubmitting;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 py-8 app-main-container gap-6">
      <AppCard className="h-fit w-full space-y-8">
        <TabHeader
          subtitle="Step 1"
          title="Initial Input"
          description="Start by providing a text description or uploading an image of the fabric you're looking for. Under the fabric type option, select your preferred type to guide the results. "
        />
        <div className="space-y-8">
          <div className="flex p-1 bg-app-black-secondary rounded-lg">
            {STEP1_TABS?.map((_tab, index) => (
              <button
                key={index}
                className={`flex-1 p-2 sm:p-3 text-sm sm:text-base rounded font-bold ${
                  _tab?.id === activeStep1Tab?.id
                    ? 'bg-app-brand text-app-black-primary'
                    : 'bg-app-black-secondary text-app-white-primary'
                }`}
                onClick={() => setActiveStep1Tab(_tab)}
              >
                {_tab?.name}
              </button>
            ))}
          </div>
          <div className="space-y-4">
            {activeStep1Tab?.id === 1 && (
              <div className="space-y-2 sm:space-y-4">
                <Select
                  options={fabricTypes?.data?.map((d) => ({ value: d?.type, label: d?.nm }))}
                  placeholder="Choose fabric type"
                  selectedOption={fabricType}
                  setSelectedOption={(selected) => setFabricType(selected)}
                />
                <TextArea
                  title="Text Prompt"
                  description="Describe the fabric you have in mind, and our AI will generate options based on your text prompt."
                  placeholder="Enter your text prompt here"
                  value={message}
                  onChange={onChangeMessage}
                  onSend={onSubmit}
                  disabled={isSubmitting}
                />
              </div>
            )}
            {activeStep1Tab?.id === 2 && (
              <div className="space-y-2 sm:space-y-4">
                <SectionLabel
                  title="Upload Image"
                  description="Input an image that reflects the fabric you're looking to customize."
                />
                <div className="space-y-1 sm:space-y-2">
                  {images?.length > 0 ? (
                    <div
                      {...getRootProps()}
                      className="w-fit border rounded-xl p-3 cursor-pointer"
                      onClick={openModal}
                    >
                      <AppImage
                        className="w-40 h-40 rounded-lg object-cover"
                        src={images?.[0]?.thumbnail}
                        alt={`Uploaded`}
                      />
                      <input
                        type="file"
                        accept=".jpeg, .png, .gif"
                        {...getInputProps()}
                      />
                    </div>
                  ) : (
                    <>
                      <input
                        ref={inputFileRef}
                        accept=".jpeg, .png, .jpg"
                        className="hidden"
                        type="file"
                        onChange={handleFileUpload}
                      />
                      <button
                        {...getRootProps()}
                        className="border rounded-md w-full px-8 h-40 text-xs sm:text-sm text-gray-400"
                        onClick={() => inputFileRef?.current?.click()}
                      >
                        Drop your image here or click to upload
                        <input
                          type="file"
                          accept=".jpeg, .png, .gif"
                          {...getInputProps()}
                        />
                      </button>
                      <p className="text-[10px] sm:text-xs text-app-grey-tertiary">
                        Image must be at least 256x256 pixels
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="flex gap-4 justify-end">
              {activeStep1Tab?.id === 2 && images?.length > 0 && (
                <Button
                  disabled={isSubmitting}
                  className="border border-app-brand text-app-brand px-2 py-1 rounded text-xs sm:text-sm font-medium"
                  type="secondary"
                  onClick={() => setImages([])}
                  text="Clear"
                />
              )}
              <Button
                className="w-full sm:w-[180px]"
                isSubmitting={isSubmitting}
                disabled={disableSend}
                onClick={onSubmit}
                text="Find"
              />
            </div>
          </div>
        </div>
        <div ref={endOfPageRef} />
      </AppCard>
      <ResultCard
        cardTitle="Results"
        cardDescription="Select a fabric from the generated results."
        images={fabrics?.image_path}
        descriptions={[{ title: 'Fabric Type', description: fabrics?.fabric_type_nm }]}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        isSubmitting={isSubmitting}
        handleContinue={() => handleChangeTab(2)}
      />
      <ImageModal
        isOpen={imageModal?.isOpen}
        imageSrc={parseUrl(images?.[0]?.preview)}
        onClose={closeModal}
      />
    </div>
  );
}

export default Tab1;
