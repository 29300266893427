import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon';
import CopyIcon from 'assets/icons/CopyIcon';
import Button from 'components/button/Button';
import AppImage from 'components/image/base/AppImage';
import Tooltip from 'components/tooltip/Tooltip';
import moment from 'moment';
import React from 'react';
import { parseUrl } from 'utils/helpers';

function Content({
  mainImage,
  stepNumberLabel,
  stepLabel,
  createdDate,
  generatedImages,
  textureMaps,
  promptText,
  colorText,
  patternText,
  imageInput,
  fabricText,
  downloadLink,
  onView,
}) {
  return (
    <div className="space-y-4">
      <div className="flex space-x-3 items-start py-2">
        <div className="flex-1 flex space-x-3 items-center">
          <AppImage
            className="w-16 h-16 rounded object-cover"
            src={parseUrl(mainImage?.path)}
            alt={mainImage?.path}
          />
          <div className="flex-1">
            <p className="text-[10px] sm:text-xs font-bold text-app-brand-text">
              {stepNumberLabel}
            </p>
            <p className="text-sm sm:text-base font-bold">{stepLabel}</p>
            <p className="text-[10px] sm:text-xs text-app-grey-tertiary">
              {moment(createdDate).format('MMM DD, YYYY HH:mm')}
            </p>
          </div>
        </div>
        {onView && (
          <button
            onClick={onView}
            className="text-app-icon-info underline text-xs sm:text-sm"
          >
            View
          </button>
        )}
      </div>
      {(promptText || colorText || patternText || imageInput) && (
        <div className="bg-app-black-secondary rounded-xl px-3 py-2">
          {fabricText && (
            <>
              <div className="space-y-1">
                <p className="text-xs sm:text-sm font-bold">Fabric Type</p>
                <p className="text-xs sm:text-sm">{fabricText}</p>
              </div>
              <div className="mb-3 pb-3 border-b" />
            </>
          )}
          {(colorText || patternText) && (
            <>
              <div className="flex">
                {colorText && (
                  <div className="flex-1 space-y-1">
                    <p className="text-xs sm:text-sm font-bold">Color</p>
                    <p className="text-xs sm:text-sm">{colorText}</p>
                  </div>
                )}
                {colorText && patternText && <div className="mr-3 pr-3 border-r" />}
                {patternText && (
                  <div className="flex-1 space-y-1">
                    <p className="text-xs sm:text-sm font-bold">Pattern</p>
                    <p className="text-xs sm:text-sm">{patternText}</p>
                  </div>
                )}
              </div>
              <div className="mb-3 pb-3 border-b" />
            </>
          )}
          {promptText && (
            <>
              <div className="space-y-2">
                <div className="space-y-1">
                  <p className="text-xs sm:text-sm font-bold">Prompt</p>
                  <p className="text-xs sm:text-sm">{promptText}</p>
                </div>
                <Tooltip
                  trigger="click"
                  text="Copied!"
                  type="success"
                >
                  <button
                    className="flex space-x-1 py-1 items-center"
                    onClick={() => navigator.clipboard.writeText(promptText)}
                  >
                    <CopyIcon />
                    <p className="text-xs sm:text-sm font-semibold text-app-icon-info underline">
                      Copy Prompt
                    </p>
                  </button>
                </Tooltip>
              </div>
            </>
          )}
          {imageInput && (
            <div className="space-y-1">
              <p className="text-xs sm:text-sm font-bold">Image</p>
              <div>
                <AppImage
                  src={parseUrl(imageInput)}
                  alt={imageInput}
                  className="w-10 sm:w-12 h-10 sm:h-12 rounded object-cover border border-app-grey-primary"
                  iconClassName="w-4.5 h-4.5 fill-app-icon-primary"
                />
              </div>
            </div>
          )}
        </div>
      )}
      {generatedImages?.length > 0 && (
        <div className="space-y-2">
          <p className="text-xs sm:text-sm font-bold">Images</p>
          <div className="flex space-x-2 overflow-auto">
            {generatedImages?.map((image, index) => (
              <AppImage
                key={index}
                src={parseUrl(image?.path)}
                alt={image?.path}
                className="w-10 sm:w-12 h-10 sm:h-12 rounded object-cover border border-app-grey-primary"
                iconClassName="w-4.5 h-4.5 fill-app-icon-primary"
              />
            ))}
          </div>
        </div>
      )}
      {textureMaps?.length > 0 && (
        <div className="space-y-2">
          <p className="text-xs sm:text-sm font-bold">Results</p>
          <div className="flex space-x-2">
            {textureMaps?.map((image, index) => (
              <Tooltip
                key={index}
                text={image?.name}
              >
                <AppImage
                  key={index}
                  src={parseUrl(image?.path)}
                  alt={image?.path}
                  className="w-10 sm:w-12 h-10 sm:h-12 rounded object-cover border border-app-grey-primary"
                  iconClassName="w-4.5 h-4.5 fill-app-icon-primary"
                />
              </Tooltip>
            ))}
          </div>
        </div>
      )}
      {textureMaps?.length > 0 && (
        <div className="space-y-2">
          <p className="text-xs sm:text-sm font-bold">Export Format</p>
          <div className="flex space-x-2">
            <div className="relative">
              <select className="text-xs sm:text-sm bg-app-black-primary border border-[#f1f1f1] min-w-28 outline-none px-3 py-2 rounded-md [::-ms-expand]:hidden appearance-none">
                <option value="png">PNG</option>
              </select>
              <ChevronLeftIcon className="absolute right-0 top-0 mt-2 sm:mt-3 mr-2 w-4 h-4 fill-app-white-primary -rotate-90" />
            </div>
            <Button
              text="Export"
              onClick={() => window.open(parseUrl(downloadLink))}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Content;
