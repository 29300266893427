import React from 'react';

import Button from 'components/button/Button';

import AppCard from 'components/card/base/AppCard';
import TabHeader from 'components/tab/TabHeader';
import BackButton from 'components/button/BackButton';
import ResultCard from 'components/card/ResultCard';
import ExportIcon from 'assets/icons/ExportIcon';
import { useNavigate } from 'react-router-dom';
import AppImage from 'components/image/base/AppImage';
import { parseUrl } from 'utils/helpers';

function Tab3({
  maps,
  submittingGenerateMaps,
  selectedGenImage,
  handleChangeTab,
  generatedFabrics,
  setSelectedGenImage,
  handleGenerateMaps,
  handleSaveResults,
  isSaved,
}) {
  const navigate = useNavigate();
  const handleSelect = (image) => {
    handleGenerateMaps(image);
    setSelectedGenImage(image);
  };

  const handleDone = async () => {
    await handleSaveResults();
    navigate('/');
  };
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 py-8 app-main-container gap-6">
      <AppCard>
        <div className="w-full space-y-8">
          <TabHeader
            subtitle="Step 3"
            title="Selected Fabric"
            description="This is the AI-generated image you selected as the base for creating texture maps."
          />
          <div className="space-y-8">
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4">
              {generatedFabrics?.image_path?.map((image, index) => {
                return (
                  <button
                    key={index}
                    className={`min-w-20 sm:min-w-40 w-full aspect-square transition-all cursor-pointer rounded-xl p-3 sm:p-4 ${
                      selectedGenImage?.no === image?.no
                        ? 'bg-app-brand border border-app-brand'
                        : submittingGenerateMaps
                        ? 'border border-app-white-primary opacity-40 hover:opacity-40 cursor-default'
                        : 'border border-app-white-primary hover:opacity-80'
                    }`}
                    onClick={() => handleSelect(image)}
                    disabled={submittingGenerateMaps}
                  >
                    <AppImage
                      src={parseUrl(image?.path)}
                      alt={image?.path}
                      className="w-full h-full rounded-lg object-cover"
                    />
                  </button>
                );
              })}
            </div>

            <div className="flex justify-between">
              <BackButton onClick={() => handleChangeTab(2)} />
            </div>
          </div>
        </div>
      </AppCard>
      <ResultCard
        cardTitle="Texture Maps Results"
        cardDescription="Here are the detailed texture maps generated for your fabric."
        images={maps?.image_path}
        isSubmitting={submittingGenerateMaps}
        actionButton={
          <div className="flex gap-2 sm:gap-4">
            <Button
              disabled={!maps}
              icon={<ExportIcon />}
              type="secondary"
              text="Export"
              onClick={() => window.open(parseUrl(maps?.zip_path))}
            />
            {!isSaved && (
              <Button
                onClick={handleDone}
                disabled={!maps || submittingGenerateMaps}
                text="Save"
              />
            )}
          </div>
        }
      />
    </div>
  );
}

export default Tab3;
